







import { Component, Vue } from 'vue-property-decorator'
import 'flexboxgrid/dist/flexboxgrid.min.css'

@Component({
  components: {},
})
export default class App extends Vue {}
