
































































import { Component, Prop, Vue } from 'vue-property-decorator'
import axios from 'axios'
import Gallery from '@/components/Gallery.vue'
import Frame from '@/components/Frame.vue'
import { Section } from '@/types'

@Component({
  components: {
    Frame,
    Gallery,
  },
})
export default class Home extends Vue {
  sections: Section[] = []
  galleryImageUrl: string | null = null
  galleryImageDesc: string | null = null

  created() {
    axios.get('/photos/index.json').then(response => {
      this.sections = response.data
    })
  }

  clickImage(image) {
    this.galleryImageUrl = image.url
    this.galleryImageDesc = image.desc.replace(/=/g, ' ')
  }
}
