









import { Component, Prop, Vue } from 'vue-property-decorator'
import { Image } from '@/types'

@Component
export default class Gallery extends Vue {
  @Prop() private imageUrl!: string | null
  @Prop() private imageDesc!: string | null
}
